import React, { useState } from "react";
import { Row, Col, Grid } from "react-flexbox-grid";
import { useAlert } from "react-alert"
import axios from "axios";

import { initialize } from "@ltv-apm-modules/react";

let otel = initialize({
  name: "bvunsubscribe",
  url: process.env.REACT_APP_APM_URL,
  apikey: process.env.REACT_APP_APM_KEY,
});

function UnsubscribeForm() {
  function logErrorOTEL(error) {
    const { api } = otel;

    if (api.isOTELInitialized()) {
      let errorMsg = `${error.message}, Cause: ${error.cause}`;
      if (error.response !== undefined) {
        errorMsg = `${errorMsg}, Status: ${error.response.status}, Data: ${JSON.stringify(error.response)}`;
      }

      api.pushError(new Error(errorMsg));
    }
  }

  const [email, setEmail] = useState("");
  const alert = useAlert();

  const handleChange = (event) => {
    setEmail(event.target.value)
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!(isEmail(email))) {
      alert.error("Please enter a valid email address");
      return
    }
    unsubscribeEmail(email).then(res => {
      if (res.status >= 200 && res.status < 300) {
        alert.success(<div className="notification-text">Successfully Unsubscribed <span className="truncate">{email}</span> <span className="redirect">Now redirecting...</span></div>, {
          onClose: () => {
            window.location.href = "https://www.beenverified.com";
          }
        });
      } else {
        alert.error("An error occurred. Please try again later.");
      }
    })
      .catch(_ => {
        alert.error("An error occurred. We are aware and working on it. Please try again later.");
      });
  };

  const isEmail = (email) => {
    // https://www.4codev.com/react/the-example-for-check-valid-email-format-in-react-idpx1567194306355418166.html
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return email ? re.test(String(email).toLowerCase()) : false;
  };

  const unsubscribeEmail = async (email) => {
    const config = {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_UNSUBSCRIBE_ENDPOINT_API_KEY}`
      }
    };
    const body = {
      vendor: `bv-email.com`,
      email: `${email}`,
    };

    try {
      return await axios.post(process.env.REACT_APP_UNSUBSCRIBE_ENDPOINT_URL, body, config);
    } catch (e) {
      const body = {
        channel: "#data-eng-alerts",
        text: "The BVUnsubscribe frontend failed to hit the backend. Check this ASAP.",
        icon_emoji: "alert-sign",
        username: "BVUnsubscribe Alerter",
        blocks: [
            {
              "type": "section",
              "text": {
                "type": "mrkdwn",
                "text": `<!subteam^${process.env.REACT_APP_DE_SLACK_GROUP_ID}>. There was an error when trying to hit the backend:`
              }
            }
        ],
        attachments: [
            {
                "color": "danger",
                "fields": [
                {
                    "title": "Error",
                    "value": `${e.message}`,
                    "short": false
                }
                ]
            }
        ]
      };

      await fetch(process.env.REACT_APP_SLACK_WEBHOOK_URL, {
        method: "POST",
        body: JSON.stringify(body),
      });
      logErrorOTEL(e);
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid>
        <Row className="form" center="xs">
          <Col id="form-input" xs={12} sm={12} md={10} lg={10}>
            <input className="input block" type="text" name="email" placeholder="Email" onChange={handleChange} value={email} />
          </Col>
          <Col id="form-button" xs={12} sm={12} md={2} lg={2}>
            <button className="btn block" type="submit">Submit</button>
          </Col>
        </Row>
      </Grid>
    </form>
  );
}

export default UnsubscribeForm;
